// import logo from "./logo.svg";
import "animate.css";
import "lightbox.js-react/dist/index.css";
import "./App.css";
import Modal from "./component/Dialog";
import Router from "./routers";
import "react-responsive-carousel/lib/styles/carousel.min.css";
function App() {
  return (
    <div className="">
      <Modal />
      <Router />
    </div>
  );
}

export default App;
