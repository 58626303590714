import React, { useEffect } from "react";
import pendulum from "../../assets/img/banner/contact_bg.webp";
import Courseimg from "../../assets/img/banner/course-banner.jpg";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import Contactsection from "../../component/contactformsection";
import Gallerysection from "../../component/gallerysection";
import Worktogether from "../../component/wrktogether";
import "./index.css";
import image from "../../assets/img/gallery/fees.jpg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Buttonredirct from "../../component/button-redirect";
import Pricing from "../../component/pricing";

const Fee = () => {
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []);

  const feesStructure = [
    {
      link: "Introduction",
      time: "20 Minute Session  ",
      price: "1500",
      heading: "What’s Include",
      list: [
        {
          icon: "⦿",
          label: "Connect a call ",
        },
        {
          icon: "⦿",
          label: "Ask 3-4 questions",
        },
        {
          icon: "⦿",
          label: "Introduction to spiritual healing",
        },
        {
          icon: "⦿",
          label: "Clear Away All Doubts",
        },
      ],
      defaultExpanded: true,
      margin: "25px !important",
    },
    {
      link: "Standard ",
      time: " 1/2 hour Session  ",
      price: "3500",
      heading: "What’s Include",
      list: [
        {
          icon: "⦿",
          label: "Get an expert psychic reading",
        },
        {
          icon: "⦿",
          label: "Pendulum Reading",
        },
        {
          icon: "⦿",
          label:
            " Ask Any Questions related to life, love, relationships, mental health,.etc.",
        },
        {
          icon: "⦿",
          label: " More Accurate Than Tarot",
        },
      ],
      defaultExpanded: false,
      margin: "45px !important",
    },
    {
      link: "Premium",
      time: "1 hour Session  ",
      price: "6000",
      heading: "What’s Include",
      list: [
        {
          icon: "⦿",
          label: "Heal for a Better Life",
        },
        {
          icon: "⦿",
          label: "Clearing Energy & Blockages",
        },
        {
          icon: "⦿",
          label: "Holistic Therapy & Chakra Healing",
        },
        {
          icon: "⦿",
          label: "Discover Root Cause of Suffering",
        },
      ],
      defaultExpanded: false,
      margin: "45px !important",
    },
  ];
  return (
    <div>
      <div className="container-fluid margintopall feebg  px-0  ">
        <div className="conatctoverlay">
          <div className="container midpartbanner ">
            <div className="row ">
              <div className="col-sm-10 text-center mx-auto">
                <div>
                  <Typography
                    variant="h3"
                    color="#fff"
                    className="Navpagesheading"
                    fontSize={30}
                  >
                    Detailed Information on Costs and Session Arrangements
                  </Typography>
                  <Typography
                    variant="h4"
                    className="Navpagessubheading"
                    color="#fff"
                    mt={3}
                  >
                    Investment & Appointment
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bigwraper">
        <div className="col-sm-12 mx-auto">
          <div className="row ">
            <div className="col-sm-6 whatifcol">
              <div className="innerpadding-all">
                <h5 class="title">FEES</h5>
                <div className="feetoppara py-2">
                  <Typography variant="p">
                    Therapy is a profound investment in one’s emotional and
                    mental well-being, fostering personal growth and healing.
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="p">
                    It involves time, commitment, and a willingness to embrace
                    change. Below are the session fees, along with guidance on
                    seeking reimbursement through insurance, supporting the path
                    to inner balance and transformation.
                  </Typography>
                  <br></br>
                  <br></br>

                  {/* <div className="buttonparent mt-5 ">
                    <Buttonredirct
                      buttonname="Book A Consultation Call"
                      buttonname2="Book A Consultation Call"
                    />
                  </div> */}
                  <h5 class="title ">Therapy Services</h5>
                  <Typography variant="p">
                    Heal and transform with Anu Kulkarni through online therapy
                    via video or phone. Enjoy personalized sessions from the
                    comfort of your home, lasting 20 minutes to 1 hour, tailored
                    to your needs for balance and growth.
                  </Typography>
                  <br></br>
                  <br></br>
                  <Typography variant="b">
                    Now accepting new clients, you're invited to begin your
                    journey towards inner peace and well-being.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <img alt="" src={image} width={"100%"} />
            </div>
          </div>

          <h5 class="title ">Therapy Fees</h5>

          <Grid container className="bigwraper" spacing={3}>
            {feesStructure.map((val, i) => (
              <Grid item lg={4} xs={12} key={i}>
                <Pricing
                  heading={val.link}
                  price={val.price}
                  time={val.time}
                  list={val.list}
                  id={i}
                />
              </Grid>
            ))}
          </Grid>
          <div className="row">
            <img className="w-100" src={wrktogtherstrip} alt=""></img>
          </div>
          <div className="row thoughtbg spaceblock px-3">
            <div className="thoughtparent ">
              <span className="thoughtpara">
                “I am not what happened to me, I am what I choose to become.”
              </span>
            </div>

            <span className="thoughtname mb-0">— Carl Jung</span>
          </div>
        </div>
      </div>
      <div className="container" id="course">
        <div className="row">
          <div className="col-sm-12 mx-auto ">
            <div className=" innerpadding-all">
              <h5 class="title ">COURSE</h5>
              <p className="">
                This immersive six-hour healing experience includes Chakra
                Balancing, Aura Reading, and Energy Cleansing. It’s designed to
                rejuvenate your entire being, helping you rediscover inner
                peace, strength, and spiritual clarity.
              </p>
              <div className="row ">
                <div className="col-sm-6">
                  <img className="w-100" src={Courseimg} alt=""></img>
                </div>
                <div className="col-sm-6 thoughtbg  p-4">
                  <h5 class="title2">How to seek reimbursement</h5>
                  <h5 class="title2 ">
                    SIX HOUR SESSION :
                    <span className="coursehighlight">₹6000</span>{" "}
                  </h5>
                  <ul className="Specialitieslist">
                    <li>
                      <p>Energy Clearing</p>
                    </li>
                    <li>
                      <p>Pendulum Therapy</p>
                    </li>
                    <li>
                      <p>Chakra Healing</p>
                    </li>
                    <li>
                      <p>Aura Reading</p>
                    </li>
                    <li>
                      <p>Life Empowerment</p>
                    </li>
                    <li>
                      <p>Soul Realignment</p>
                    </li>
                  </ul>
                  <div class="buttonparent ">
                    <Buttonredirct
                      buttonname="MAKE AN APPOINTMENT"
                      buttonname2="MAKE AN APPOINTMENT"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Worktogether />

      <Gallerysection />
      <Contactsection
        heading="Set Up Your Online Session for Healing Therapy"
        img={pendulum}
        para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session."
        para2="Have questions or need more information? Feel free to call or text at anuakatakyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation.
"
        button="BOOK A CONSULTATION CALL"
        button2="BOOK A CONSULTATION CALL"
      />
      <div className="container-fluid px-0 Goodfaithbg">
        <div className="container bigwraper">
          <h5 class="title">Understanding Your Good Faith Estimate</h5>
          <p>
            At Anu Kulkarni's practice, transparency is a key part of your
            healing journey. You are entitled to receive a “Good Faith Estimate”
            outlining the costs for your holistic and healing therapy
            sessions, ensuring a clear view of your investment in your
            well-being.
          </p>
          <p>
            Request this estimate before scheduling your session to stay
            informed and empowered about your path to transformation.
          </p>
          <p>
            Curious about how this benefits you? Explore more about your right
            to a Good Faith Estimate and how it supports your journey with Anu
            Kulkarni. We are dedicated to guiding you with clarity and support
            throughout your healing process.
          </p>
          {/* <p>
            If you receive a bill that is at least $400 more than your Good
            Faith Estimate, you can dispute the bill. Make sure to save a copy
            or picture of your Good Faith Estimate.
          </p>
          <p>
            For questions or more information about your right to a Good Faith
            Estimate, visit <a href=""> www.cms.lol/nosurprises. </a>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Fee;
