import React from "react";
import Data from "../../assets/data";
import { MdOutlineStar } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import "./index.css";

import { Link } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";

const Testimonialsection = () => {
  const phone = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div className="container-fluid testimonialbg bigwraper">
        <div className="container  text-center">
          <h4 className={`heading-color ${phone && "mt-4"} mb-5`}>
            What People are Saying
          </h4>
          <div className="row ">
            {Data.testimonialsection.map((val, id) => (
              <div className="col-sm-4">
                <MdOutlineStar />
                <MdOutlineStar />
                <MdOutlineStar />
                <MdOutlineStar />
                <MdOutlineStar />
                <p>{val.para}</p>
                <p>
                  <b> {val.name}</b>
                </p>
              </div>
            ))}
          </div>

          <Link to={"/testimonial"}>
            <Button sx={{ color: "#03492f" }} endIcon={<FaArrowRightLong />}>
              See Other testimonials
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Testimonialsection;
