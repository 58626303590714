import React from "react";
import useGlobalNavigate from "../Globalbutton/index";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/reducers/modal";
import ContactForm from "../contactForm";

const Buttonredirct = (props) => {
  const navigateTo = useGlobalNavigate();
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    // navigateTo("/contact");
    dispatch(showModal(<ContactForm />));
  };
  return (
    <div>
      <button
        href=""
        onClick={handleButtonClick}
        type="button"
        className=" Allbutton"
      >
        <span class="text">{props.buttonname}</span>
        <span class="text">{props.buttonname2}</span>
      </button>
    </div>
  );
};

export default Buttonredirct;
