import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Data from "../../assets/data";
import "./index.css";
import pendulum from "../../assets/img/gallery/Client-pendulum.jpeg";
import { MdStarRate } from "react-icons/md";
import Buttonredirct from "../../component/button-redirect";
import { Typography } from "@mui/material";
import Contactsection from "../../component/contactformsection";
const Testimonial = () => {
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts
  return (
    <div>
      <div className="container-fluid margintopall testimonialpagebg  px-0  ">
        <div className="overlaybanner">
          <div className="container midpartbanner ">
            <Typography
              variant="h3"
              className="Navpagesheading"
              textAlign={"center"}
            >
              Real Stories of Healing and Transformation
            </Typography>
            <Typography
              variant="h4"
              className="Navpagessubheading"
              mt={5}
              textAlign={"center"}
            >
              What People are saying
            </Typography>
          </div>
        </div>
      </div>
      <div className="container bigwraper  text-center">
        {/* <h4>What People are saying </h4> */}
        <div className="row  innerpadding-all">
          {Data.testimonialpage.map((val, id) => (
            <div className="col-sm-4 mt-4" key={id}>
              <div>
                <p>
                  <MdStarRate />
                  <MdStarRate />
                  <MdStarRate />
                  <MdStarRate />
                  <MdStarRate />
                </p>
                <p>"{val.para}"</p>
              </div>
              <p className="username">{val.name}</p>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="container-fluid spaceblock abouthealerbg px-0">
        <div className="container ">
          <div className="buttonparent text-center ">
            <Buttonredirct 
             buttonname="Book An Appointment"
             buttonname2="Book An Appointment"/>
          </div>
        </div>
      </div> */}
      <Contactsection
        img={pendulum}
        heading="Set Up Your Online Session for Healing Therapy"
        para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session. "
        para2="Have questions or need more information? Feel free to call or text at anuakatakyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation.
"
        button="Make an Appointment"
        button2="Make an Appointment"
      />
    </div>
  );
};

export default Testimonial;
