import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Buttonredirct from "../button-redirect";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/reducers/modal";
import ContactForm from "../contactForm";

const Pricing = ({ heading, price, time, list, id }) => {
  //   console.log("first", data);
  const phone = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(showModal(<ContactForm />));
  };
  return (
    <div>
      <Card
        sx={{
          p: 3,
          color: "#000",
          height: 550,
        }}
      >
        <Typography variant="h3" fontSize={13} fontWeight={550}>
          {heading}
        </Typography>
        <Typography
          fontSize={35}
          fontWeight={550}
          variant="h1"
          my={2}
          color={"#03492F"}
        >
          ₹ {price}
        </Typography>
        <Typography variant="h3" fontSize={17}>
          {time}
        </Typography>
        <Divider
          sx={{
            borderColor: "#000",
            my: 2,
            ":hover": {
              borderColor: "#fff",
            },
          }}
        >
          <Typography fontWeight={500} fontSize={14}>
            Features
          </Typography>
        </Divider>
        <List>
          {list.map((val, i) => (
            <ListItem key={i} disablePadding>
              <ListItemAvatar className="golddots">{val.icon}</ListItemAvatar>
              <ListItemText
                primary={
                  <Typography margin="5px !important" fontSize={13}>
                    {val.label}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ position: "relative" }}>
          <button
            className="Allbutton"
            style={{
              position: "absolute",
              top: id !== 1 ? 70 : 50,
              width: phone && "100%",
            }}
            onClick={handleOpenModal}
          >
            <span>Book A consulation Call</span>
            <span>Book A consulation Call</span>
          </button>
        </Box>
      </Card>
    </div>
  );
};

export default Pricing;
