import React from "react";
import { MdOutlineStar } from "react-icons/md";
import "./index.css";
const Singletestimonial = (props) => {
  return (
    <div>
      <div className="container-fluid testimonial-bg bigwraper">
        <div className="container aboutpading text-center">
          <h4 className="heading-color">What People are Saying</h4>
          <div className="row pt-4 ">
            <div className="col-sm-6 mx-auto">
              <MdOutlineStar />
              <MdOutlineStar />
              <MdOutlineStar />
              <MdOutlineStar />
              <MdOutlineStar />
              <p>“{props.para}”</p>
              <p>
                <b> {props.name}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Singletestimonial;
