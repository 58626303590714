import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Anukarnilogo from "../../assets/img/logo/AnuHealerTherapistLogo.png";

import "../navbar/index.css";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/reducers/modal";
import ContactForm from "../contactForm";
import { Close, Menu } from "@mui/icons-material";
import Data from "../../assets/data";
const Navbarr = () => {
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => setShowDropdown(true);
  const handleMouseLeave = () => setShowDropdown(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    // navigate("/contact");
    dispatch(showModal(<ContactForm />));
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const show = Boolean(anchorEl);
  const handleRoute = (e, val) => {
    if (val === "/specialities") {
      setAnchorEl(e.currentTarget);
    } else {
      navigate(val);
      setOpen(false);
      setAnchorEl(null);
    }
  };

  const phoneMediaQuery = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="container-fluid navbg">
        {phoneMediaQuery ? (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            p={1}
          >
            <Link to={"/"}>
              <img
                className="anukulkarnimg rounded-circle"
                src={Anukarnilogo}
                alt="Logo"
              />
            </Link>
            <IconButton onClick={() => setOpen(true)}>
              <Menu htmlColor="#000" sx={{ fontSize: 35 }} />
            </IconButton>
            <Drawer
              open={open}
              anchor="left"
              sx={{
                "& .MuiDrawer-paper": {
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{ p: 2 }}
                display="flex"
                justifyContent={"space-between"}
              >
                <Link to={"/"}>
                  <img
                    className="anukulkarnimg rounded-circle"
                    src={Anukarnilogo}
                    alt="Logo"
                  />
                </Link>
                <IconButton onClick={() => setOpen(false)}>
                  <Close />
                </IconButton>
              </Box>
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <List>
                  {Data.FooterRoutes.map((val, i) => (
                    <ListItemButton
                      onClick={(e) => handleRoute(e, val.url)}
                      sx={{ justifyContent: "center" }}
                    >
                      <Typography sx={{ fontSize: 25, textAlign: "center" }}>
                        {val.label}
                      </Typography>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            </Drawer>
            <Popover
              anchorEl={anchorEl}
              open={show}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              onClose={() => setAnchorEl(null)}
              className="customPopoverClass"
              sx={{
                "& .MuiPaper-root-MuiPopover-paper": {
                  boxShadow: "none",
                },
              }}
            >
              <List>
                {Data.specialities.map((val, i) => (
                  <ListItemButton
                    key={i}
                    onClick={(e) => handleRoute(e, val.url)}
                  >
                    {val.label}
                  </ListItemButton>
                ))}
              </List>
            </Popover>
          </Stack>
        ) : (
          <div className="container">
            <div className="row py-3">
              <div className="col-sm-2 anukulkarnimg-container">
                <Navbar.Brand as={Link} to="/">
                  <img
                    className="anukulkarnimg rounded-circle"
                    src={Anukarnilogo}
                    alt="Logo"
                  />
                </Navbar.Brand>
              </div>
              <div className="col-sm-8 px-0 d-flex justify-content-center">
                <div className="navlinkcontent ">
                  <Nav.Link
                    as={Link}
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    Welcome
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about"
                    className={location.pathname === "/about" ? "active" : ""}
                  >
                    About
                  </Nav.Link>

                  <div
                    className="dropdownspecility"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Nav.Link
                      as={Link}
                      to="#"
                      className={
                        location.pathname.startsWith("/specialties")
                          ? "active"
                          : ""
                      }
                    >
                      Specialities
                    </Nav.Link>
                    {showDropdown && (
                      <ul className="specialitiesmenu">
                        <li>
                          <Nav.Link
                            as={Link}
                            to="/anxiety"
                            className={
                              location.pathname === "/anxiety" ? "active" : ""
                            }
                          >
                            Anxiety
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to="/chronic-illness"
                            className={
                              location.pathname === "/chronic-illness"
                                ? "active"
                                : ""
                            }
                          >
                            Chronic Illness
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to="/depression"
                            className={
                              location.pathname === "/depression"
                                ? "active"
                                : ""
                            }
                          >
                            Depression
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to="/spiritualityblockages"
                            className={
                              location.pathname === "/spiritualityblockages"
                                ? "active"
                                : ""
                            }
                          >
                            Spritual Blockages
                          </Nav.Link>
                        </li>
                      </ul>
                    )}
                  </div>

                  <Nav.Link
                    as={Link}
                    to="/testimonial"
                    className={
                      location.pathname === "/testimonial" ? "active" : ""
                    }
                  >
                    Testimonials
                  </Nav.Link>
                  <div
                    className="dropdownspecility"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Nav.Link
                      as={Link}
                      to="/fee"
                      className={location.pathname === "/fee" ? "active" : ""}
                    >
                      Fees
                    </Nav.Link>
                    {/* {showDropdown && (
                    <ul className="specialitiesmenu">
                      <li>
                        <Nav.Link
                          as={Link}
                          to="/fee#course"
                          className={
                            location.pathname === "/fee/course" ? "active" : ""
                          }
                        >
                          Course
                        </Nav.Link>
                      </li>
                    </ul>
                  )} */}
                  </div>
                  <Nav.Link
                    as={Link}
                    to="/contact"
                    className={location.pathname === "/contact" ? "active" : ""}
                  >
                    Contact
                  </Nav.Link>
                </div>
              </div>
              <div className="col-sm-2  headerbtnparent">
                <button
                  href=""
                  onClick={handleButtonClick}
                  type="button"
                  className="  Allbutton buttonstyle ms-2 "
                >
                  <span class="text">BOOK NOW</span>
                  <span class="text">bOOK NOW</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbarr;
