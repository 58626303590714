import React from "react";
import { useNavigate } from "react-router-dom";

const Globalbutton = () => {

    const navigate = useNavigate();

    const navigateTo = (path) => {
      navigate(path);
    };

    return navigateTo;

 
}

export default Globalbutton;
