import React from "react";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import "./index.css";
const Believe = () => {
  return (
    <div>
      <div className=" testimonial_bg">
        <div className="container aboutpading text-center  ">
          <div className="row text-center">
            <div className="col-sm-6 mx-auto">
              <p>
                You are worth so much more. We heal, when we are aware. We heal
                when we connect to our true essence. The root cause of our
                suffering lies in disconnecting from our thoughts and emotions,
                in repression and refusal to feel. Your emotions are your
                power—they belong to you, waiting to be reclaimed. You are made
                of star dust and light, you are the expansive universe.
                Understanding yourself is understanding the universe. For, the
                universe is inside you. Know it.
              </p>
              <h4 className="heading-color">Healing is possible.</h4>
            </div>
          </div>
          <div className="imgblockspace">
            <img className="w-100" src={wrktogtherstrip} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Believe;
