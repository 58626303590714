import React, { useEffect } from "react";
import "./index.css";
import Singletestimonial from "../../component/singletestimonial";
import Worktogether from "../../component/wrktogether";
import Contactsection from "../../component/contactformsection";
import pendulum from "../../assets/img/gallery/Client-pendulum.jpeg";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Aboutsection from "../../component/aboutsection";
import Aboutspecialities from "../../component/aboutspecialities";
import Abtanxietyimg from "../../assets/img/banner/anxiety-banner.jpg";
import Anxietysigncol1 from "../../assets/img/elements/Anxietysigncol1.jpg";
import Anxietysigncol2 from "../../assets/img/elements/Anxietysigncol2.jpg";
import anxietybg from "../../assets/img/banner/anxiety__banner.png";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import Helpyou from "../../component/Helpyou";
import Wrktogthrhlpyou from "../../component/worktogetherhlpyou";
import Buttonredirct from "../../component/button-redirect";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const Anxiety = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = [
    {
      heading: "I help people just like you, struggling with:",
      list: [
        {
          icon: "⦿",
          label: "Managing Persistent Worry",
        },
        {
          icon: "⦿",
          label: "Overcoming Panic Attacks",
        },
        {
          icon: "⦿",
          label: "Healing from Past Traumas",
        },
        {
          icon: "⦿",
          label: "Navigating Life Transitions",
        },
        {
          icon: "⦿",
          label: "Finding Inner Peace",
        },
        {
          icon: "⦿",
          label: "Breaking Free from Limiting Beliefs",
        },
      ],
    },
    {
      heading: "Anxiety Symptoms:",
      list: [
        {
          icon: "⦿",
          label: "Restlessness: Feeling on edge or unable to relax.",
        },
        {
          icon: "⦿",
          label: "Panic Attacks: Sudden, intense fear with physical symptoms.",
        },
        {
          icon: "⦿",
          label:
            "Difficulty Concentrating: Trouble focusing due to racing thoughts.",
        },
        {
          icon: "⦿",
          label: "Muscle Tension: Tight or sore muscles from stress.",
        },
        {
          icon: "⦿",
          label: "Constant Worry: Excessive anxiety about daily issues.",
        },
        {
          icon: "⦿",
          label: "Sleep Disturbances: Trouble sleeping due to anxiety.",
        },
      ],
    },
  ];

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  return (
    <div>
      <div className="container-fluid margintopall Anxitypagebg  px-0  ">
        <div className="conatctoverlay">
          <div className="container midpartbanner ">
            <div className="row">
              <div className="col-sm-8 text-center mx-auto">
                <div>
                  <Typography
                    variant="h5"
                    fontSize={{ lg: 30, xs: 16 }}
                    textTransform={"uppercase"}
                    letterSpacing={1}
                    lineHeight={"1.5em"}
                    color="#fff"
                  >
                    <strong>
                      You’re not just your thoughts, not just your body. You are
                      so much more than that. Do not limit the perception of
                      self.
                      <Typography
                        variant="h5"
                        fontSize={{ lg: 30, xs: 16 }}
                        textTransform={"uppercase"}
                        letterSpacing={1}
                        lineHeight={"1.5em"}
                        color="#fff"
                        fontWeight={600}
                        mt={1}
                      >
                        Therapy For Anxiety Can Help
                      </Typography>
                    </strong>
                  </Typography>
                </div>
                <div className="buttonparent text-center mt-1 ">
                  <Buttonredirct
                    buttonname="Book A Consultation Call"
                    buttonname2="Book A Consultation Call"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Aboutspecialities
        img={Abtanxietyimg}
        heading="Anxiety Counseling + Treatment In-Person + Online Globally"
        description="Anxiety seems daunting and difficult to tackle. A racing mind and a heart thumping louder than your own voice. There are days you wake up feeling your breath has returned to normal, your fear has stepped away from your thoughts, behaviors and emotions. You are happy, you are proud. You’re taking out time for yourself to finish your things."
        strong="Until suddenly, there is a familiar feeling creeping up on you, yet again."
        para="Keeping you awake at night for hours. A tightness in your chest with a clock ticking above your head. You’re on high alert again. Immersing, more than observing. Tired. This is the back-forth and non-linear nature of Anxiety. It comes and goes but nevertheless stays. Not knowing when is the next time it’d show up."
        strong2="You know you can push through and are determined that you can stop this. That you want to stop this. Just that you are unsure what exactly to do."
        para1="Don’t worry, I’ve got you."
        para2="As a Holistic healer and therapist, I specialize in treating anxiety and its symptoms. My approach focuses on identifying the root causes across all spaces, of the mind and the energy with its relation to the body. Beginning this therapy will help you regulate and bring your emotions into awareness. Calm down racing thoughts and find relief from stress and chaos. Ultimately, healing all emotional blockages."
        para3="Wherever you are. If you are looking for a treatment that does not necessitate the use of medications and ensures your utmost well-being Connect with me. Schedule a session call to get started. We’ll discuss and answer your questions, if felt right— you can book your first healing session."
        button="Book A Consultation call"
        button2="Book A Consultation call"
        stripimg={wrktogtherstrip}
      /> */}
      <div className="container bigwraper  ">
        <Grid container className="innerpadding-all ">
          <Grid item lg={6}>
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: 24,

                  lg: 30,
                },
              }}
              mb={2}
            >
              Anxiety Counseling + Treatment In-Person + Online Globally
            </Typography>
            <Typography variant="p" mt={2}>
              Anxiety seems daunting and difficult to tackle. A racing mind and
              a heart thumping louder than your own voice. There are days you
              wake up feeling your breath has returned to normal, your fear has
              stepped away from your thoughts, behaviors and emotions. You are
              happy, you are proud. You’re taking out time for yourself to
              finish your things.
            </Typography>
            <br />
            <br />
            <Typography variant="p" fontWeight={550}>
              Until suddenly, there is a familiar feeling creeping up on you,
              yet again.
            </Typography>
            <br />
            <br />
            <Typography variant="p">
              Keeping you awake at night for hours. A tightness in your chest
              with a clock ticking above your head. You’re on high alert again.
              Immersing, more than observing. Tired. This is the back-forth and
              non-linear nature of Anxiety. It comes and goes but nevertheless
              stays. Not knowing when is the next time it’d show up.
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <img alt="" src={Abtanxietyimg} width={"100%"} height={"100%"} />
          </Grid>
        </Grid>
        <Grid container className="innerpadding-all" mt={3}>
          <Grid item lg={12}>
            <Typography variant="p" fontWeight={550}>
              You know you can push through and are determined that you can stop
              this. That you want to stop this. Just that you are unsure what
              exactly to do.
            </Typography>
            <br />
            <br />
            <Typography variant="p">Don’t worry, I’ve got you.</Typography>
            <br />
            <br />
            <Typography variant="p">
              As a Holistic healer and therapist, I specialize in treating
              anxiety and its symptoms. My approach focuses on identifying the
              root causes across all spaces, of the mind and the energy with its
              relation to the body. Beginning this therapy will help you
              regulate and bring your emotions into awareness. Calm down racing
              thoughts and find relief from stress and chaos. Ultimately,
              healing all emotional blockages.
            </Typography>
            <Typography variant="p">
              Wherever you are. If you are looking for a treatment that does not
              necessitate the use of medications and ensures your utmost
              well-being Connect with me. Schedule a session call to get
              started. We’ll discuss and answer your questions, if felt right—
              you can book your first healing session.
            </Typography>
            <Box mt={3}>
              <div className="buttonparent mt-4 ">
                <Buttonredirct
                  buttonname="Book A Consultation Call"
                  buttonname2="Book A Consultation Call"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Box mt={3}>
        <Aboutsection
          img={Healerimg}
          headinglarge="Hi, I’m Anu Kulkarni. A spiritual holistic healer & Therapist"
          para2="What you are going through, it  is not a life sentence. Change and healing is possible. Emancipation is within you. "
          para3="As a holistic spiritual therapist, I offer you restoration of your spiritual and physical balance with a heart-centric approach, through energy work."
          para4="For over 23+ years, I have been working towards empowering minds, in the wake of new world interconnectedness. I know the resolve. I know how to help you discover the root cause of your anxiety, and create a lasting change to live life to its fullest potential."
          button="BOOK A CONSULTATION CALL"
          button2="BOOK A CONSULTATION CALL"
        />
      </Box>

      <div className="container">
        <Grid container mt={10} alignItems="center">
          <Grid item lg={6}>
            <Carousel
              showThumbs={false}
              showArrows={true}
              showIndicators={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    style={{ ...arrowStyles, left: 15 }}
                  >
                    <ArrowBackIos htmlColor="#000" />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    style={{ ...arrowStyles, right: 15 }}
                  >
                    <ArrowForwardIos htmlColor="#000" />
                  </IconButton>
                )
              }
            >
              {data.map((val, i) => (
                <Box key={i}>
                  <Typography variant="h3" fontSize={25} textAlign={"center"}>
                    {val.heading}
                  </Typography>
                  <List>
                    {val.list.map((value, id) => (
                      <ListItem key={id}>
                        <ListItemAvatar className="golddots">{value.icon}</ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="p">{value.label}</Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Carousel>
          </Grid>
          <Grid item lg={6}>
            <img src={anxietybg} className="img-fluid" alt="" />
          </Grid>
        </Grid>
      </div>
      <Helpyou
        heading="There’s hope and help available for what you’re going through. Therapy for anxiety can empower you to:"
        paraheading="Therapy for anxiety can help you learn to:"
        name1="Practice Self-care and Nurture"
        para1="You can feel calm through chaos, love and invest in yourself in a productive way."
        name2="Replace racing thoughts with inner harmony"
        para2="Learn to find stillness and be mindful of your actions and thoughts. So you can gain back your control and power over self."
        name3="Reconnect the mind with the body"
        para3="Break away from self-sabotaging patterns and unhealthy coping. Learn of your inner wisdom and how to use it."
      />
      <Singletestimonial
        para="Anu it’s all thanks to you . You gave me a lot of strength and courage by working on me so deligently every day . That encouraged me to come for the wonderful workshop you had for us. Anu always be blessed."
        name="Saloni K."
      />
      <Worktogether />
      <div className="container-fluid wrktogthrhlpyoubg">
        <Wrktogthrhlpyou
          heading="Find balance and relief from anxiety with therapy:"
          list1="Reprogram your mind for safety and confidence, easing fears and doubts."
          list2="Reduce sensory overload and clear away feelings of overwhelm and insecurity."
          list3="Develop deep self-trust and embrace self-love."
          list4="Break free from recurring challenges and debilitating emotions."
          list5="Tap into inner wisdom and enhance your intuition for guidance."
          subheading="Purpose and Wisdom is within you. Therapy For Anxiety Can Help!"
          strong="I look forward to connecting!"
          sign="xo Anu"
          button="Make An Appointment"
          button2="Make An Appointment"
        />
      </div>
      <div className=" anxiety-parallax px-0"></div>
      <Contactsection
        img={pendulum}
        heading="Set Up Your Online Session for Anxiety Therapy"
        para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session. "
        para2="Have questions or need more information? Feel free to call or text at anuakatakyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation.
"
        button="Make an Appointment"
        button2="Make an Appointment"
      />
    </div>
  );
};

export default Anxiety;
