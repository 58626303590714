import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";
import "./index.css";
import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { hideModal } from "../../redux/reducers/modal";

export default function Modal() {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.modal.content);

  function closeModal() {
    dispatch(hideModal());
  }

  const modalBackgroundAnimation = useSpring({
    opacity: content ? 1 : 0,
    transform: content ? "translateY(0)" : "translateY(-20px)",
    config: { tension: 200, friction: 20 },
  });

  const modalContentAnimation = useSpring({
    opacity: content ? 1 : 0,
    transform: content ? "scale(1)" : "scale(0.9)",
    config: { tension: 200, friction: 20 },
  });

  return (
    <animated.div
      style={modalBackgroundAnimation}
      onClick={(e) => {
        if (e.target.classList.contains(`modal__background`)) {
          closeModal();
        }
      }}
      className={`modal__background ${content ? "d-flex" : "d-none"}`}
    >
      <animated.div style={modalContentAnimation} className={`modal__content `}>
        <Box textAlign="end">
          <IconButton className={`modal__content__close`} onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
        {content}
      </animated.div>
    </animated.div>
  );
}
