import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import Data from "../../assets/data";
import logo from "../../assets/img/logo/AnuHealerTherapistLogo.png";
import { Email, LocationOn, Phone } from "@mui/icons-material";
const Footer2 = () => {
  const handleRoute = (val) => {
    window.location.href = val;
  };
  const phone = useMediaQuery("(max-width:600px)");

  const location = useLocation();

  const list1 = [
    {
      label: "About",
      url: "/about",
    },
    {
      label: "Testimonial",
      url: "/testimonial",
    },
    {
      label: "Fee",
      url: "/fee",
    },
    {
      label: "Contact",
      url: "/contact",
    },
  ];
  const list2 = [
    {
      label: "Anxiety",
      url: "/anxiety",
    },
    {
      label: "Chronic Illness",
      url: "/chronic-illness",
    },
    {
      label: "Depression",
      url: "/depression",
    },
    {
      label: "Spiritual Blockages",
      url: "/spiritualityblockages",
    },
  ];
  const list3 = [
    {
      label: "Mumbai, India",
      icon: <LocationOn />,
    },
    {
      label: "anuakatakyst@gmail.com",
      icon: <Email />,
    },
    {
      label: "+91 9986094405",
      icon: <Phone />,
    },
  ];
  const navigate = useNavigate();
  const handleRouter = (url) => {
    navigate(url);
  };
  return (
    <Box sx={{ px: { xs: 1 }, pt: 4, borderTop: "1px solid #d7d7d7" }}>
      <div className="container">
        <Stack
          direction={{ lg: "row", xs: "column" }}
          alignItems={{ lg: "center" }}
          justifyContent={"space-between"}
          mb={0}
          // p={2}
        >
          <Box>
            <Typography
              fontSize={{ lg: 25, xs: 15 }}
              fontWeight={550}
              letterSpacing={1.3}
              sx={{
                margin: "0 !important",
              }}
            >
              Sign Up to Newsletter
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
              mt={{ xs: 2 }}
            >
              Join the monthly newsletter to receive meditations on life and
              affirmations to inspire + heal.
            </Typography>
          </Box>
          <Stack
            direction={{ lg: "row", xs: "column" }}
            alignItems={"center"}
            spacing={{ lg: 4, xs: 2 }}
            mt={{ xs: 3 }}
          >
            <TextField
              placeholder="Enter Your Email Address"
              sx={{ width: { lg: 300 } }}
              fullWidth={phone}
            />
            <button className="Allbutton" style={{ width: phone && "100%" }}>
              <span>Subscribe</span>
              <span>Subscribe</span>
            </button>
          </Stack>
        </Stack>
        <Grid container>
          <Grid
            item
            lg={3}
            xs={12}
            pl={0}
            pr={4}
            sx={{
              pt: { lg: 4, xs: 4 }, pb:{lg:4, xs:2}
            }}
          >
            <div className="mobileviewicons">
              <div
                className="d-flex "
                style={{ justifyContent: "space-between" }}
              >
                <img alt="" className="anukulkarnimg " src={logo} width={100} />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  {Data.socialIcons.map((val, i) => (
                    <IconButton
                      sx={{
                        fontSize: 20,
                        ":hover": {
                          color: "#fff",
                          backgroundColor: val.bgColor,
                        },
                        transition: " 0.5s ease all",
                      }}
                      key={i}
                      onClick={() => handleRoute(val.url)}
                    >
                      {val.icon}
                    </IconButton>
                  ))}
                </Stack>
              </div>
              <Typography className="mb-0" fontSize={12}>
                Anu Kulkarni is Spiritual Healer and Holistic Therapist,
                providing online and in-person therapy aid to individuals of all
                age groups.
              </Typography>
            </div>
            <div className="desktopviewicons">
              <div>
                <img alt="" src={logo} width={100} />
                <Typography fontSize={12}>
                  Anu Kulkarni is Spiritual Healer and Holistic Therapist,
                  providing online and in-person therapy aid to individuals of
                  all age groups.
                </Typography>
              </div>

              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {Data.socialIcons.map((val, i) => (
                  <IconButton
                    sx={{
                      fontSize: 20,
                      ":hover": {
                        color: "#fff",
                        backgroundColor: val.bgColor,
                      },
                      transition: " 0.5s ease all",
                    }}
                    key={i}
                    onClick={() => handleRoute(val.url)}
                  >
                    {val.icon}
                  </IconButton>
                ))}
              </Stack>
            </div>
          </Grid>

          <Grid item lg={3} xs={6} sx={{ p: { xs: 0, lg: 4 } }}>
            <List>
              <Typography
                fontWeight={550}
                fontSize={20}
                p={2}
                sx={{ margin: "0 !important" }}
              >
                Company
              </Typography>
              {list1.map((val, i) => (
                <ListItemButton
                  //   sx={{ width: "fit-content" }}
                  key={i}
                  onClick={() => handleRouter(val.url)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          margin: "2px !important",
                          fontSize: 15,
                          //   borderBottom:
                          //     location.pathname === val.url &&
                          //     "1px solid #008000",
                        }}
                      >
                        {val.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item lg={3} xs={6} sx={{ p: { xs: 0, lg: 4 } }}>
            <List>
              <Typography
                fontWeight={550}
                fontSize={20}
                p={2}
                sx={{ margin: "0px !important" }}
              >
                Services
              </Typography>
              {list2.map((val, i) => (
                <ListItemButton
                  //   sx={{ width: "fit-content" }}
                  key={i}
                  onClick={() => handleRouter(val.url)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          margin: "2px !important",
                          fontSize: 15,
                          //   textDecoration:
                          //     location.pathname === val.url && "underline",
                        }}
                      >
                        {val.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item lg={3} xs={12} sx={{ p: { xs: 0, lg: 4 } }}>
            <List>
              <Typography
                fontWeight={550}
                fontSize={20}
                p={2}
                sx={{ margin: "0px !important" }}
              >
                Contact
              </Typography>
              {list3.map((val, i) => (
                <ListItem key={i}>
                  <ListItemAvatar sx={{ minWidth: 20 }}>
                    {val.icon}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      val.icon.type === Email ? (
                        <a
                          href={`mailto:${val.label}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            sx={{
                              margin: "2px !important",
                              fontSize: 15,
                            }}
                          >
                            {val.label}
                          </Typography>
                        </a>
                      ) : val.icon.type === Phone ? (
                        <a
                          href={`tel:${val.label}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            sx={{
                              margin: "5px !important",
                              fontSize: 15,
                            }}
                          >
                            {val.label}
                          </Typography>
                        </a>
                      ) : (
                        <Typography
                          sx={{
                            margin: "5px !important",
                            fontSize: 15,
                          }}
                        >
                          {val.label}
                        </Typography>
                      )
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Divider sx={{ border: "1px solid #000" }} />
        <Stack
          direction={{ lg: "row", xs: "column" }}
          alignItems={{ lg: "center", xs: "flex-start" }}
          justifyContent={"space-between"}
        >
          <Typography fontSize={12}>
            © {new Date().getFullYear()}, AnuTheTherapist | Holistic Therapist &
            Holistic Healer | Virtual Healing Counseling & Online Therapy in
            Global
          </Typography>
          <Typography fontSize={12}>Made by Digixito ❤</Typography>
        </Stack>
      </div>
    </Box>
  );
};

export default Footer2;
