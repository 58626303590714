import React from "react";
import "./index.css";
const Helpyou = (props) => {
  return (
    <div className="container text-center bigwraper ">
      <div className="row">
        <div className="col-sm-12 mx-auto innerpadding-all">
            <div className="row ">
                <div className="col-sm-9 mx-auto">
                <h5 class="title2  ">{props.heading}</h5>
                <p className="heading2anxiety">{props.paraheading}</p>
                    </div> </div>
       

          <div className="row ">
            <div className="col-sm-4  ">
              <div className="px-3">
                <p>
                  <strong>{props.name1}</strong>
                </p>
                <p>{props.para1}</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="px-3">
                <p>
                  <strong>{props.name2}</strong>
                </p>
                <p>{props.para2}</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="px-3">
                <p>
                  <strong>{props.name3}</strong>
                </p>
                <p>{props.para3}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Helpyou;
