import React, { useState } from "react";
import "./index.css";
import {
  Box,
  Divider,
  Grid,
  InputLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { FaExclamationCircle } from "react-icons/fa";
const Form = () => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let error = "";

    if (name === "firstName") {
      if (!value.trim()) {
        error = "First name is required";
      } else if (value.length < 2 || value.length > 50) {
        error = "First name must be between 2 and 50 characters";
      }
    }

    if (name === "lastName") {
      if (!value.trim()) {
        error = "Last name is required";
      } else if (value.length < 2 || value.length > 50) {
        error = "Last name must be between 2 and 50 characters";
      }
    }

    if (name === "email") {
      if (!value.trim()) {
        error = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Email is invalid";
      }
    }

    if (name === "phone") {
      if (!value.trim()) {
        error = "Phone number is required";
      } 
    }

 

    if (name === "subject") {
      if (!value.trim()) {
        error = "Subject is required";
      }
    }

    if (name === "message") {
      if (!value.trim()) {
        error = "Message is required";
      }
    }

    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    for (const [name, value] of Object.entries(formValues)) {
      const error = validateField(name, value);
      if (error) {
        newErrors[name] = error;
      }
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Submit form
      console.log("Form submitted", formValues);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    // Validate field and set error
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };
  const handlePhoneChange = (value) => {
    setFormValues({ ...formValues, phone: value });
    const error = validateField('phone', value);
    setErrors({ ...errors, phone: error });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-6 mb-3">
            <label>
              First Name <span className="required ">*</span>
            </label>
            <input
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              style={{
                width: "100%",
                border: "1px solid #a9a9a9",
                padding: "10px",
                lineHeight: "1.8rem",
              }}
            />
            {errors.firstName && (
              <p className="error">
                <FaExclamationCircle className="me-1" />
                {errors.firstName}
              </p>
            )}
          </div>
          <div className="col-sm-6 mb-3">
            <label>
              Last Name <span className="required ">*</span>
            </label>
            <input
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              style={{
                width: "100%",
                border: "1px solid #a9a9a9",
                padding: "10px",
                lineHeight: "1.8rem",
              }}
            />
            {errors.lastName && (
              <p className="error">
                <FaExclamationCircle className="me-1" />
                {errors.lastName}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-3">
            <label>
              Email <span className="required ">*</span>
            </label>
            <input
              name="email"
              value={formValues.email}
              onChange={handleChange}
              style={{
                width: "100%",
                border: "1px solid #a9a9a9",
                padding: "10px",
                lineHeight: "1.8rem",
              }}
            />
            {errors.email && (
              <p className="error">
                <FaExclamationCircle className="me-1" />
                {errors.email}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-3">
            {/* <label>
              Phone <span className="required ">*</span>
            </label> */}
            {/* <input
              type="number"
              name="phone"
              value={formValues.phone}
              onChange={handleChange}
              style={{
                width: "100%",
                border: "1px solid #a9a9a9",
                padding: "10px",
                lineHeight: "1.8rem",
              }}
            /> */}
            <InputLabel sx={{ fontWeight: 550, fontSize: 15 }}>
              Phone Number*
            </InputLabel>
            <MuiTelInput
              fullWidth
              defaultCountry="US"
              sx={{ mt: 1 }}
              value={formValues.phone}
              onChange={handlePhoneChange}
              error={!!errors.phone}
            />
            {errors.phone && (
              <p className="error">
                <FaExclamationCircle className="me-1" />
                {errors.phone}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-3">
            <label>
              Subject <span className="required ">*</span>
            </label>
            <input
              name="subject"
              value={formValues.subject}
              onChange={handleChange}
              style={{
                width: "100%",
                border: "1px solid #a9a9a9",
                padding: "10px",
                lineHeight: "1.8rem",
              }}
            />
            {errors.subject && (
              <p className="error">
                <FaExclamationCircle className="me-1" />
                {errors.subject}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <label>
              Message <span className="required">*</span>
            </label>
            <textarea
              name="message"
              value={formValues.message}
              onChange={handleChange}
              style={{
                width: "100%",
                border: "1px solid #a9a9a9",
                padding: "10px",
                lineHeight: "1.8rem",
              }}
            />
            {errors.message && (
              <p className="error">
                <FaExclamationCircle className="me-1" />
                {errors.message}
              </p>
            )}
          </div>
        </div>
        <div className="buttonparent text-left">
          <button type="submit" className="Allbutton">
            <span className="text">SUBMIT</span>
            <span className="text">SUBMIT</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
