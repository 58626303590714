import React from "react";
import Data from "../../assets/data";
import "./index.css";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import Buttonredirct from "../button-redirect";
import { Avatar } from "@mui/material";

const Worktogether = () => {
  return (
    <div>
      <div className="container-fluid bigwraper worktogetherbg  pb-0 ">
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-12 mx-auto">
              <section className="row mb-5">
                <div className="col-sm-8 mx-auto">
                  <h5 className="title heading-color">
                    READY TO WORK TOGETHER?
                  </h5>
                  <h5 className="heading-color">Here’s what to expect:</h5>
                </div>
              </section>
              <section className="row">
                {Data.Worktother.map((val, id) => (
                  <div className="col-sm-4 mb-2" key={id}>
                    {/* <div className="circlediv mx-auto">{val.number}</div> */}
                    <Avatar
                      sx={{ backgroundColor: "#19442A", margin: "auto", mb: 1 ,width: { xs: 35}, height: { xs: 35 } }}
                    >
                      {val.number}
                    </Avatar>
                    <h5 className="wrksentence"  >{val.sentence}</h5>
                  </div>
                ))}
              </section>
              <div className="buttonparent mt-4 ">
                <Buttonredirct
                  buttonname="Book A Consultation Call"
                  buttonname2="Book A Consultation Call"
                />
              </div>
              {/* <div className="spaceblock"></div> */}
              <div className="imgblockpspace ">
                <img className="w-100" src={wrktogtherstrip} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Worktogether;
