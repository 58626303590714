import React from "react";
import Data from "../../assets/data";
import "./index.css";
import { Link } from "react-router-dom";

const Gallerysection = () => {
  return (
    <div>
      <div className="container-fluid abouthealerbg">
        <div className="container spaceblock  text-center ">
          <section className="row my-3 ">
            {Data.gallery.map((val, id) => (
              <div className="col-sm-4  mb-2 ">
                <div className="galryparent" key={id}>
                  <img src={val.img} alt=""></img>
                  <div className="insstripoverlay"></div>
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default Gallerysection;
