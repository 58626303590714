import React from "react";
import "./index.css";
import Form from "../form";
import Buttonredirct from "../button-redirect";
import { useMediaQuery } from "@mui/material";

const Contactsection = (props) => {
  const phone = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div className="container-fluid bigwraper  abouthealerbg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12  ">
              <div className="row align-items-center">
                <div className="col-sm-6 text-justify  ">
                  <div className="">
                    <h5 className="title2 heading-color">{props.heading}</h5>
                    <p>{props.para}</p>
                    <p>{props.para2}</p>
                    <div className="buttonparent mt-3">
                      <Buttonredirct
                        buttonname={props.button}
                        buttonname2={props.button2}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 ">
                  {/* <div className="innerpadding-all">
                    <Form />
                  </div> */}
                  <img
                    src={props.img}
                    className={` ${phone && "mt-4"}`}
                    alt=""
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactsection;
