import React, { useEffect } from "react";
import "./index.css";

import pendulum from "../../assets/img/banner/contact_bg.webp";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";

import AOS from "aos";
import "aos/dist/aos.css";
import Data from "../../assets/data";
import Aboutsection from "../../component/aboutsection";
import Believe from "../../component/Believe";
import Buttonredirct from "../../component/button-redirect";
import Contactsection from "../../component/contactformsection";
import Testimonialsection from "../../component/testimonialsection";
import Worktogether from "../../component/wrktogether";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import image from "../../assets/img/banner/bg-healer.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { MdNavigateNext } from "react-icons/md";
import { IoChevronBackSharp } from "react-icons/io5";
import Ecard from "../../component/e-card";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleNavLinkClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };
  const data = [
    {
      heading: "I help people just like you, struggling with:",
      list: [
        {
          icon: "⦿",
          label: "Past traumas ",
        },
        {
          icon: "⦿",
          label: "Relationship issues",
        },
        {
          icon: "⦿",
          label: "Fears and limiting beliefs",
        },
        {
          icon: "⦿",
          label: "Feeling stuck or unmotivated",
        },
      ],
    },
  ];

  return (
    <div>
      <div className="container-fluid homepage p-0">
        <div className="">
          <div className="container">
            <section className="row ">
              <div className=" col-sm-12  homebgoverlay homepageallcon ">
                <div className=" spacebanheading  ">
                  <div className="">
                    <h2 className="bannerheading ">Root to consiousness.</h2>
                    <h2 className="bannerheading2 ">
                      Healing and change is always possible.
                    </h2>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* about-site */}
      <div className="px-0 pt-0 worktogetherbg  ">
        <div className="aboutbg bigwraper ">
          <div className="container txtcenter    ">
            <section className="row " id="Aboutsection">
              <div className="col-sm-12 mx-auto">
                {/* <h4 className="heading-color">Hello and Welcome </h4> */}
                <Typography variant="h4" textAlign={"center"} color="#034929">
                  Did you know
                </Typography>
                <Box sx={{ mt: 3 }} className=" innerpadding-all">
                  <Typography variant="p" textAlign={"center"} fontSize={15}>
                    Everything in this universe, including our feelings, is a
                    form of energy that vibrates and resonates. We process more
                    than{" "}
                    <Typography variant="span" fontWeight={600}>
                      60,000 thoughts in a single day{" "}
                    </Typography>
                    —most of them on autopilot! Ever find yourself asking what
                    you were just thinking?
                  </Typography>

                  <Typography mt={3} fontSize={15}>
                    These{" "}
                    <Typography variant="span" fontWeight={600} gap={2}>
                      thoughts and feelings
                    </Typography>{" "}
                    are deposited into the energy points in our body called
                    <Typography variant="span" fontWeight={600} gap={2}>
                      {" "}
                      Chakras
                    </Typography>
                    . When your thoughts become{" "}
                    <Typography variant="span" fontWeight={600} gap={2}>
                      positive,
                    </Typography>{" "}
                    your Chakras vibrate with powerful,{" "}
                    <Typography variant="span" fontWeight={600} gap={2}>
                      uplifting energy{" "}
                    </Typography>
                    that makes you vibrant and full of life. Just imagine the
                    wonderful changes a simple shift in thinking can bring,
                    unlocking a whole new energized you!
                  </Typography>
                  <Typography mt={3} fontSize={15}>
                    Isn't it amazing how one little tweak in your way of
                    thinking can unlock a whole new, revitalized you?
                  </Typography>

                  <Box className="buttonparent " sx={{ mt: 5 }}>
                    <Buttonredirct
                      buttonname="Book A Consultation Call"
                      buttonname2="Book A Consultation Call"
                    />
                  </Box>
                </Box>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* banner start  */}

      <div className="container-fluid bigwraper  banner_bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              {/* <Carousel
                showThumbs={false}
                autoPlay={false}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                swipeable={true}
              > */}
              <Box>
                <Typography
                  // fontSize={{ lg: "48px !important", xs: "25px !important" }}

                  fontWeight={400}
                  color={"#034929"}
                  variant="h4"
                >
                  Change is the only constant
                </Typography>

                <Typography
                  variant="h5"
                  gap={2}
                  fontSize={{ lg: 17, xs: 13 }}
                  lineHeight={1.5}
                  mt={{ lg: 4, xs: 3 }}
                >
                  Change is constant, and overcoming challenges can raise your
                  life to lasting joy. Continuous happiness is possible!
                </Typography>
                <Typography
                  variant="p"
                  gap={2}
                  fontSize={{ lg: 17, xs: 13 }}
                  lineHeight={1.5}
                  mt={{ lg: 4, xs: 3 }}
                >
                  Overcome challenges to unlock lasting joy. Address these to
                  achieve continuous happiness:
                </Typography>

                {/* <Box
                    component="ul"
                    sx={{
                      listStyleType: "none", // Remove default bullets
                    }}
                  >
                    <Typography
                      component="li"
                      fontWeight={600} // Bold font for list items
                      fontSize={15}
                      lineHeight={1.5}
                      sx={{ marginBottom: 1 }} // Space between list items
                    >
                      • Past traumas
                    </Typography>
                    <Typography
                      component="li"
                      fontWeight={600} // Bold font for list items
                      fontSize={15}
                      lineHeight={1.5}
                      sx={{ marginBottom: 1 }} // Space between list items
                    >
                      • Relationship issues
                    </Typography>
                    <Typography
                      component="li"
                      fontWeight={600} // Bold font for list items
                      fontSize={15}
                      lineHeight={1.5}
                      sx={{ marginBottom: 1 }} // Space between list items
                    >
                      • Fears and limiting beliefs
                    </Typography>
                    <Typography
                      component="li"
                      fontWeight={600} // Bold font for list items
                      fontSize={15}
                      lineHeight={1.5}
                      sx={{ marginBottom: 1 }} // Space between list items
                    >
                      • Feeling stuck or unmotivated
                    </Typography>
                  </Box> */}
                <div className="col-sm-5 mx-auto">
                  {data.map((val, i) => (
                    <Box key={i} px={{ lg: 2, xs: 2 }}>
                      <List px={{ lg: 3, xs: 2 }}>
                        {val.list.map((value, id) => (
                          <ListItem key={id}>
                            <ListItemAvatar className="golddots">
                              {value.icon}
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="p"
                                  fontSize={{ xs: 13, lg: 15 }}
                                >
                                  {value.label}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ))}
                </div>
              </Box>
              {/* </Carousel> */}
            </div>
          </div>
        </div>
      </div>

      {/* banner end  */}

      <div className="container bigwraper  ">
        <Grid className="innerpadding-all" container>
          <Grid item lg={6} className="whatifcol">
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: 23,

                  lg: 30,
                },
              }}
              fontSize={30}
            >
              What if a shift in your <br /> energy could change <br /> the
              world?
            </Typography>
            <Typography>
              It starts with{" "}
              <Typography variant="span" fontWeight={600}>
                you
              </Typography>
              . <br />
            </Typography>

            <Typography mt={3} fontSize={15}>
              By raising your vibration, you unlock new opportunities and
              transform your surroundings.{" "}
              <Typography variant="span" fontWeight={600}>
                Your personal change
              </Typography>{" "}
              can create a ripple effect, influencing the world positively.
            </Typography>
            <Typography mt={3} fontSize={15}>
              Ready to make the change?
            </Typography>

            <Box className="buttonparent mt-auto " sx={{ my: 3 }}>
              <Buttonredirct
                buttonname="Book A Consultation Call"
                buttonname2="Book A Consultation Call"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <img src={image} width="100%" alt="" />
          </Grid>
        </Grid>
      </div>

      <Ecard></Ecard>
      {/* <div className="container-fluid pb-5">
        <div className="container  txtcenter  ">
          <section className="row ">
            <div className="col-sm-12 mx-auto">
              <h5 className="title heading-color">SPECIALITIES</h5>
            </div>
          </section>
          <section className="row">
            {Data.specialitiesImage.map((val, id) => (
              <div className="col-sm-3" key={id}>
                <img src={val.img} alt="" width={"100%"} />
              </div>
            ))}
          </section>
        </div>
      </div> */}
      {/* end specialities */}
      {/* About Healer */}

      <div className="container-fluid px-0 bigwraper abouthealerbg ">
        <Aboutsection
          img={Healerimg}
          italichead={"I Am Here for you….. "}
          para="Hi, I’m Anu Kulkarni. A spiritual holistic healer & Therapist"
          strongpara="Gently guiding you on a path toward deep inner healing and transformation."
          para2="Just like you’d hire a trainer to strengthen your body, I’m here to support and guide you on your journey to emotional and personal growth. Our inner world, much like our physical well-being, needs attention and care. Whether you want to outgrow negativity or rise to your fullest potential, I am here with the tools and support to guide you through this process.
"
          para3="This is the ideal time, as you are reading this, to get yourself in the race of exploring and developing your inner self. Let me take you on an exciting journey together toward getting strong, joyful, and blissful. It requires nothing from your side except the desire for change.
"
          // strongpara2="If you are reading this then maybe this is a good head start to begin your inner journey to enhance and accelerate your inner self exploration
          // to elevate and expand your personality to new heights of strength joy and Bliss."
          // strongpara3=" and spiritual blockages, negativity and struggles of the conscious mind."
          para8="A little about me"
          para9="This is my journey into self-discovery, which started at age 18, nurtured by the spiritual seeds my parents had inducted early in my life. I have studied Indian philosophy, attended Christian schools, and engaged with diverse cultures. These experiences continuously reinvent and enrich me."
          para10="For 45 years, my spiritual foundation has brought me expanding joy. Life’s challenges test me like a boxer, but each time I rise stronger, finding light even in darkness. Join me on this journey of growth and transformation. There’s always hope and resilience, and I’m here to help you find it."

          // button="Book A Consulatation Call"
          // button2="Book A Consulatation Call"
        />
      </div>

      {/* end about Healer */}
      {/* testimonialsection */}
      <Testimonialsection />
      {/* end testimonial section */}
      <Worktogether />
      <Believe />
      <Contactsection
        heading="Set Up Your Online Session for Healing Therapy"
        img={pendulum}
        para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session. "
        para2="Have questions or need more information? Feel free to call or text at anuakatakyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation."
        button="BOOK A CONSULTATION CALL"
        button2="BOOK A CONSULTATION CALL"
      />
    </div>
  );
};

export default Home;
