import React, { useEffect } from "react";
import Modalitiesimage from "../../assets/img/elements/modalitiesbgimg.jpg";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import pendulum from "../../assets/img/banner/contact_bg.webp";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Aboutsection from "../../component/aboutsection";
import Buttonredirct from "../../component/button-redirect";
import Contactsection from "../../component/contactformsection";
import Gallerysection from "../../component/gallerysection";
import Modalities from "../../component/modal";
import Singletestimonial from "../../component/singletestimonial";
import Worktogether from "../../component/wrktogether";
import "./index.css";
import { useMediaQuery } from "@mui/material";
const Aboutpage = () => {
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const phone = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div className="bigwraper"></div>
      <div className="container-fluid px-0  ">
        <Aboutsection
          img={Healerimg}
          subheading="Anu, The Therapist"
          // detail="Email : "
          // detailpara="Anu@kulkarni.com"
          para="Holistic Healing Therapy for Spiritual Growth and Life Struggles."
          italichead2=" Hi there!"
          para2="I am a mind and body practitioner, healer and founder of the ‘Daily Energy Bath’, self-mastery course. My practices are deeply rooted in cross-cultural modalities of energy work, mindfulness, integrative pendulum and hypnotherapy, guidance and holistic healing."
          para3="My approach is informed and perches at the intersection of energy psychology, philosophy and spirituality, next to evidence-based interventions. For over 23+ years, I have been working towards empowering minds, in the wake of new world interconnectedness.  I specialize in"
          strongpara2=" anxiety and depression treatment, ache relief, trauma and"
          strongpara3=" soul healing, past life regression, hypnotherapy, pendulum healing and reading, Chakra and Aura reading and Akashic reading."
          para4="I believe that we are the divine creations, made out of one true source. The oneness."
        />
        <div className="buttonparent text-center">
          <Buttonredirct
            buttonname="Book A Consultation Call"
            buttonname2="Book A Consultation Call"
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-10 mx-auto ">
            <img className="w-100" src={wrktogtherstrip} alt="" />
            <h6 className="subheadings text-center heading-color">
              I help people just like you, struggling with:
            </h6>

            <div className="row ">
              <div className="col-sm-6">
                <ul className="Specialitieslist">
                  <li>
                    <p>Anxiety</p>
                  </li>
                  <li>
                    <p>Depression</p>
                  </li>
                  <li>
                    <p>Existential Crisis</p>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 ">
                <ul className="Specialitieslist">
                  <li>
                    <p>Chronic illness</p>
                  </li>
                  <li>
                    <p>Spiritual Blockages</p>{" "}
                  </li>
                  <li>
                    <p>Self-esteem and self-worth challenges</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!phone && (
        <Modalities
          bgImage={Modalitiesimage}
          height={"100vh"}
          modalitieshead="Orientation + Modalities"
          list1="Energy Work and Clearing"
          list2="Pendulum Healing"
          list3="Hypnotherapy"
          list4="Meditation"
          list5="Reconnective Healing"
        />
      )}
      <div className="container text-center">
        <div className="col-sm-12 mx-auto">
          <div className="row">
            <img className="w-100" src={wrktogtherstrip} alt="" />
            <h4 className="heading-color">About Me</h4>
            <div className="col-sm-12 spaceblock aboutmeparent">
              <div className=" innerpadding-all">
                <p>
                  In the art of healing, a chosen pathway to assist people in
                  releasing deep emotional and spiritual burdens, my commitment
                  has been more than two decades. It has truly been a privilege
                  since 2002 to witness fantastic transformations in persons as
                  they work through past traumas and often blast through them in
                  just a few sessions.
                </p>
                <p>
                  I walked many paths before returning to my first love, the
                  intersection of psychology and spirituality. Since I was a
                  young child pondering the size of the universe and marveling
                  at the stars, I have been fascinated with the human
                  experience. Becoming a therapist has been a catalyst for my
                  own self-transformation and the practice allows me the
                  opportunity to walk alongside others who are walking this path
                  of self-care and growth. The path of love.
                </p>
                <p>
                  As a spiritual counsellor, I try to open my inner self toward
                  finding peace of mind and, in essence, true happiness. Every
                  session is an individual journey of healing through intuition
                  followed by the use of relevant techniques. Though based in
                  Mumbai, I offer one-on-one and distant sessions. I hold space
                  for you within a safe and non-judgmental environment while you
                  take yourself through this unique journey of restoration.
                </p>
              </div>
            </div>
            <hr className="aboutpagehr"></hr>
          </div>
          <div className="row">
            <div className="col-sm-6 content-justify">
              <div className="innerpadding-all">
                <h4 className="heading-color">Background</h4>
                <p>
                  My professional journey as a healer started in 2002 when I was
                  part of the pioneer batch of hypnotherapists certified by the
                  California Institute of Hypnosis under Dr. Sunny Satin in
                  Mumbai. Ever since then, my specialization in hypnotherapy and
                  past life regression has enabled me to bring scores of people
                  out of deep-seated emotional and spiritual problems with
                  astoundingly successful results. Later, in 2016, I fortified
                  my skills by being certified under Dr. Eric Pearl as a
                  Reconnective Healing Practitioner. The following year, in
                  2017, I completed The Reconnection, enabling me to apply
                  powerful energy-healing techniques in balancing and aligning
                  the lives of my clients. Besides my clinical practice, I have
                  been able to put my knowledge to use in the business world
                  through Nirwana Herbal Healthcare 2010, a company retailing
                  Ayurvedic products. I also diversified into the food industry
                  with the marketing of an all-purpose gourmet masala mix from
                  P.K. Foods since 2015.
                </p>
                <p>
                  The combination of therapeutic practice with business acumen
                  helped enrich this career all the more and thus allowed me to
                  take an all-rounded approach toward holistic wellness and
                  personal growth.
                </p>
              </div>
            </div>
            <div className="col-sm-6 content-justify">
              <div className="innerpadding-all">
                <h4 className="heading-color">My Holistic Philosophy</h4>
                <p>
                  As a professional with over 23 years of experience, my
                  holistic practice embraces and honours the whole human being
                  in his or her journey to balance healing on all levels:
                  emotional, mental, and spiritual. Since 2002, I have
                  successfully used hypnotherapy for deeply ingrained issues,
                  yielding powerful changes in just a few sessions. When
                  traditional methods need just that little extra something,
                  Reconnective Healing will reinstate balance and energy on a
                  deeper level. My integrative modalities are hypnotherapy and
                  past life regression for the discovery and healing of
                  deep-seated concerns, Ayurvedic medicine for natural
                  well-being, distance healing, and Reconnection for
                  transformative energy work, with spiritual counselling to
                  connect you deeper into your inner potential by personalized
                  guidance in meditation.
                </p>
                <p>
                  This way, it can attend exclusively to your needs during every
                  session in the most complete form for a deeper level of
                  balance and fulfilment that guides you toward inner peace and
                  joy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Singletestimonial
        para="Anu it’s all thanks to you . You gave me a lot of strength and courage by working on me so  deligently every day .
      That encouraged me to come for the wonderful workshop you had for us.
Anu always be blessed."
        name="S. Nair"
      />
      <Worktogether />

      <Gallerysection />
      <Contactsection
        img={pendulum}
        heading="Set Up Your Online Session for Healing Therapy"
        para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session. "
        para2="Have questions or need more information? Feel free to call or text at anuakatakyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation.
"
        button="MAKE AN APPOINTMENT"
        button2="MAKE AN APPOINTMENT"
      />
    </div>
  );
};

export default Aboutpage;
