import React from "react";
import "./index.css";
const Modalities = (props) => {
  console.log("first", props);
  return (
    <div>
      <div className="container spaceblock  ">
        <div className="row">
          <div className="col-sm-11 mx-auto">
            <div className="row  modalitiescol1 ">
              <div className="col-sm-8">
                <div>
                  <img className="modalitiesimg" src={props.bgImage} alt="" />
                </div>
              </div>
              <div className=" modalityliparent modalitiescol2 custom-top ">
                <div className="modalitiesbgclr ">
                  <h6 className="subheadings text-center heading-color">
                    {props.modalitieshead}
                  </h6>
                  <ul className="modalitiesul">
                    <li>
                      <p>{props.list1}</p>
                    </li>
                    <li>
                      <p>{props.list3}</p>
                    </li>
                    <li>
                      <p>{props.list4}</p>
                    </li>
                    <li>
                      <p>{props.list5}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modalities;
