import React, { useEffect } from "react";
import "./index.css";
import Aboutspecialities from "../../component/aboutspecialities";
import Abtdepressionimg from "../../assets/img/banner/women-spiritual.jpg";
import Abtdepressionimg2 from "../../assets/img/banner/spiritual.jpeg";
import wrktogtherstrip from "../../assets/img/elements/wrktogtherstrip.png";
import Aboutsection from "../../component/aboutsection";
import Healerimg from "../../assets/img/gallery/galleryimg3.png";
import Modalitiesspritualityimg from "../../assets/img/elements/Modalitiesspritualityimg.jpg";
import pendulum from "../../assets/img/gallery/Client-pendulum.jpeg";
import Modalities from "../../component/modal";
import Helpyou from "../../component/Helpyou";
import Singletestimonial from "../../component/singletestimonial";
import Worktogether from "../../component/wrktogether";
import Wrktogthrhlpyou from "../../component/worktogetherhlpyou";
import Contactsection from "../../component/contactformsection";
import Buttonredirct from "../../component/button-redirect";

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

const Spritualityblockages = () => {
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const listData = [
    {
      icon: "🌿",
      primaryLabel: "Address the Root of Your Struggles : ",
      secondaryLabel: "Heal deeply to create lasting change.",
    },
    {
      icon: "🌿",
      primaryLabel: "Rewire Your Mindset : ",
      secondaryLabel: "Shift from fear to connection and love.",
    },
    {
      icon: "🌿",
      primaryLabel: "Embrace Your Spiritual Gifts : ",
      secondaryLabel: "Unlock and integrate your unique abilities.",
    },
    {
      icon: "🌿",
      primaryLabel: "Build Self-Trust and Love : ",
      secondaryLabel: "Cultivate a profound sense of self-acceptance.",
    },
    {
      icon: "🌿",
      primaryLabel: "Enhance Your Intuition : ",
      secondaryLabel: "Develop your inner guidance and clarity.",
    },
  ];

  const spirit = [
    {
      icon: "⦿",
      label: "Past traumas and unresolved experiences",
    },
    {
      icon: "⦿",
      label: "Negative thought patterns and beliefs",
    },
    {
      icon: "⦿",
      label: " Emotional baggage and unconscious fears",
    },
    {
      icon: "⦿",
      label: "Disconnection from our intuition and inner guidance",
    },
    {
      icon: "⦿",
      label: "Inherited family patterns and karma",
    },
  ];

  const spiritManifest = [
    {
      icon: "⦿",
      label: "Anxiety, depression, and emotional turmoil",
    },
    {
      icon: "⦿",
      label: "Physical pain and discomfort",
    },
    {
      icon: "⦿",
      label: "Feeling stuck or stagnant in life",
    },
    {
      icon: "⦿",
      label: "Difficulty connecting with others and ourselves ",
    },
    {
      icon: "⦿",
      label: "Lack of purpose and direction ",
    },
  ];

  const clearSpirit = [
    {
      icon: "⦿",
      label: "Identifying and understanding the root causes of your blockages ",
    },
    {
      icon: "⦿",
      label: "Releasing trapped emotions and energies ",
    },
    {
      icon: "⦿",
      label: "Reprogramming negative thought patterns and beliefs ",
    },
    {
      icon: "⦿",
      label: "Reconnecting with your intuition and inner guidance ",
    },
    {
      icon: "⦿",
      label: "Aligning your energy field with your highest potential ",
    },
  ];
  return (
    <div>
      <div className="container-fluid margintopall spiritualityBg  px-0  ">
        <div className="conatctoverlay">
          <div className="container midpartbanner ">
            <div className="row">
              <div className="col-sm-9 mx-auto ">
                <div>
                  <Typography
                    // fontSize={50}
                    className="Navpagesheading "
                    color="#fff"
                    variant="h3"
                    textAlign={"center"}
                  >
                    Clearing Spiritual Blockages
                  </Typography>
                  <Typography
                    // fontSize={20}
                    color="#fff"
                    className="Navpagessubheading"
                    variant="h4"
                    mt={2}
                    textAlign={"center"}
                  >
                    Unlocking Your Path to Wholeness
                  </Typography>
                </div>
                <Box className="buttonparent text-center" mt={3}>
                  <Buttonredirct
                    buttonname="Book A Consultation Call"
                    buttonname2="Book A Consultation Call"
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Box className="container bigwraper">
        <Grid container className=" innerpadding-all" spacing={4}>
          <Grid item lg={6} textAlign={"left"}>
            {/* <h3 style={{ letterSpacing: 1, lineHeight: 1.2 }}>
              Understanding Spiritual Blockages
            </h3> */}
            <Typography variant="p" lineHeight={1}>
              As a mind and body practitioner, I've witnessed countless
              individuals struggle with unseen forces holding them back from
              realizing their full potential. These patterns mainly come and
              stay in your energetic field from several lifetimes.These
              obstacles are energetic barriers that hinder our connection to our
              true selves, the world, and the divine.
            </Typography>
            <br />
            <Typography variant="p">
              Energetic blockages are accumulated patterns of thoughts,
              emotions, and experiences that create dissonance within our energy
              field. They can stem from many aspects of our lives broadly
              categorized as under:
            </Typography>
            <List>
              {spirit.map((val, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemAvatar className="golddots" sx={{ minWidth: 30 }}>
                    {val.icon}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant="p">{val.label}</Typography>}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item lg={6}>
            <img alt="" src={Abtdepressionimg} height="100%" width={"100%"} />
          </Grid>
        </Grid>
      </Box>

      <Helpyou
        heading="In our work together, you’ll learn to:"
        // paraheading="In our work together, you’ll learn to::"
        name1="Harmonize Mind and Spirit"
        para1="Break Free from Self-Sabotage and Embrace a Life You Love. "
        name2="Integrate Mind and Spirit"
        para2="Illuminate the Unconscious and Embrace True Healing"
        name3="Effective Self-Care for Lasting Well-Being"
        para3="Live Your Purpose Without Exhaustion"
      />

      <Box className="container" mt={5}>
        <Grid container className=" innerpadding-all" spacing={4}>
          <Grid item lg={6}>
            <Typography
              sx={{
                fontSize: {
                  xs: 23,

                  lg: 25,
                },
              }}
              variant="h3"
              lineHeight={1.2}
              textTransform={"normal"}
            >
              Through my expertise in energy work, mindfulness, integrative
              pendulum, hypnotherapy, and holistic healing, I can support you
              in:
            </Typography>

            <List >
              {clearSpirit.map((val, i) => (
                <ListItem className="ps-0" key={i}>
                  <ListItemAvatar className="golddots" sx={{ minWidth: 30 }}>
                    {val.icon}
                  </ListItemAvatar>
                  <ListItemText primary={val.label} />
                </ListItem>
              ))}
            </List>
            <Typography fontSize={15}>
              Take the first step towards liberation
            </Typography>
            <Typography mt={2} fontSize={15}>
              If you're ready to break free from spiritual blockages and embrace
              your true essence, I invite you to explore my services, including
              the 'Daily Energy Bath' self-mastery course. Together, let's
              embark on a journey of discovery, healing, and transformation.
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <img alt="" src={Abtdepressionimg2} height="100%" width={"100%"} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={5}>
        <Singletestimonial
          para="Anu it’s all thanks to you . You gave me a lot of strength and courage by working on me so  deligently every day .
          That encouraged me to come for the wonderful workshop you had for us.
          Anu always be blessed."
          name="Amit s."
        />
      </Box>

      <Contactsection
        img={pendulum}
        heading="Set Up Your Online Session for Healing Therapy"
        para="Are you ready to explore the transformative power of holistic psychotherapy and how it can enhance your journey? Let’s connect for a complimentary consultation call. Click below to schedule, and we’ll discuss how this healing path can support your growth. If it resonates, we’ll arrange your first online session."
        para2="Have questions or need more information? Feel free to call or text at anuakatakyst@gmail.com, or use the contact form to reach out. I look forward to guiding you toward inner harmony and transformation.
"
        button="Make an Appointment"
        button2="Make an Appointment"
      />
    </div>
  );
};

export default Spritualityblockages;
