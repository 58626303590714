import React from "react";
import "./index.css";
import Buttonredirct from "../button-redirect";

const Wrktogthrhlpyou = (props) => {
  return (
    <div>
      <div className="container text-center bigwraper">
        <h5 className="title">{props.heading}</h5>
        <div className="text-center">
          <div className="col-sm-8 py-3 mx-auto">
            <ul className="centered-list list-style-unstyled">
              <li>
                <p>{props.list1}</p>
              </li>
              <li>
                <p>{props.list2}</p>
              </li>
              <li>
                <p>{props.list3}</p>
              </li>
              <li>
                <p>{props.list4}</p>
              </li>
              <li>
                <p>{props.list5}</p>
              </li>
            </ul>
          </div>

          <h3 class="abouthealershort">{props.subheading}</h3>

          <p>
            <strong>{props.strong}</strong>
          </p>
          <h4>{props.sign}</h4>
          <div className="buttonparent text-center mt-3">
       
            <Buttonredirct buttonname={props.button}
            buttonname2={props.button2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wrktogthrhlpyou;
