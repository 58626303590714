import React from "react";
import Ecardimg from "../../assets/img/elements/anukulkarni-ecard.jpg";
import "./index.css";
import { Box, List, ListItem } from "@mui/material";
import { AiFillInstagram } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import Data from "../../assets/data";
const Ecard = () => {
  return (
    <div>
      <div className="container text-center pt-0 bigwraper   ">
        {/* <h5 class="title heading-color mb-4">SERVICES</h5> */}
        <div className="row mx-0 e-cardparent e-cardbg innerpadding-all ">
          <div className="col-sm-4 ps-0">
            <img className="e-cardimg" src={Ecardimg}></img>
          </div>
          <div className="col-sm-8  ">
            <div className="row pt-3 mb-2 ">
              <h3 className="text-left heading-color mb-0"> Anu kulkarni</h3>
              <span className="e-cardborder">Therapist | Healer | Mentor</span>
            </div>
            <div className="row   ecardservicesparent  ">
              {Data.ecardservices.map((val, i) => (
                <div className="col-sm-4">
                  <Box key={i} className="">
                    {val.list.map((value, i) => (
                      <List className="ecardlistpadding">
                        <div className="eservicehead">{value.heading}</div>
                        <ListItem className=" ecardlisting p-0">{value.label}</ListItem>
                      </List>
                    ))}
                  </Box>
                </div>
              ))}
            </div>
            <div className="row ecardlinks">
              <div className="col-sm-4 ecardiconsparent">
                <a
                  href="https://www.instagram.com/anuthetherapist/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="ecardicons">
                    <AiFillInstagram />
                  </span>
                </a>
                <p>
                  <a
                    href="https://www.instagram.com/anuthetherapist/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @anuthetherapist
                  </a>
                </p>
              </div>

              <div className="col-sm-4 ecardiconsparent">
                <a href="mailto:anuakatalyst@gmail.com">
                  <span className="ecardicons">
                    <IoMail />
                  </span>
                </a>
                <p>
                  <a href="mailto:anuakatalyst@gmail.com">
                    anuakatalyst@gmail.com
                  </a>
                </p>
              </div>

              <div className="col-sm-4 ecardiconsparent ">
                <a href="tel:+919986094405">
                  <span className="ecardicons">
                    <FaPhoneAlt />
                  </span>
                </a>
                <p>
                  <a href="tel:+919986094405">+91 9986094405</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecard;
