import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Data from "../../assets/data";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { Button } from "react-bootstrap";
import { useState } from "react";

const Gallery = () => {
  const [showAll, setShowAll] = useState(true);

  const handleShowAll = () => {
    setShowAll(true);
  };

  const handleShowVideo = () => {
    setShowAll(false);
  };
  return (
    <div>
      <div className="container-fluid subheaderbg">
        <div className="container">
          <div className="row">
            <div className="col-sm-8  text-center mx-auto ">
              <h3 className="subbanerheading">Gallery</h3>
              <h6 className="subheaderlinkhead">
                <Link to="/">Home</Link>/<Link to="/gallery">Gallery</Link>
              </h6>
            </div>
           
          </div>
        </div>
      </div>
      <div className="container padding-all">
        <div className="row">
          <div className="col-sm-8 mx-auto">
            <button
              className={`buttonstyle ${showAll ? "selected" : ""}`}
              onClick={handleShowAll}
            >
              All
            </button>
            <button
              className={`buttonstyle mx-4 ${!showAll ? "selected" : ""}`}
              onClick={handleShowVideo}
            >
              Video
            </button>
          </div>
        </div>
        <div className="container padding-all">
          <div className="row">
            {showAll &&
              Data.gallerypage.map((val, id) => (
                <div className="col-sm-4 mb-4" key={id}>
                  <SlideshowLightbox className="Gallerypageimg">
                    <img
                      className="Gallerypageimg"
                      src={val.img}
                      alt={`gallery ${id}`}
                    />
                  </SlideshowLightbox>
                </div>
              ))}
          </div>
          <div className="row">
            {(showAll || (!showAll && Data.gallerypage.length > 1)) &&
              Data.gallerypage.slice(1, 2).map((val, id) => (
                <div className="col-sm-4 mb-4" key={id + 1}>
                  <SlideshowLightbox className="Gallerypageimg">
                    <img
                      className="Gallerypageimg"
                      src={val.img}
                      alt={`gallery ${id + 1}`}
                    />
                  </SlideshowLightbox>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
