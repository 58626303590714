import React from "react";
import Buttonredirct from "../button-redirect";

const Aboutsection = (props) => {
  return (
    <div>
      <div className="container">
        <div className="row  ">
          <div className="col-sm-12 mx-auto ">
            <div className="row align-items-center">
              <div className="col-sm-6 healerimgparent">
                <div className="innerpadding-all">
                  <img src={props.img} className="healerimg" alt=""></img>
                </div>
              </div>
              <div className="col-sm-6">
                <div className=" innerpadding-all">
                  <h4>{props.italichead}</h4>
                  <h5 class="title heading-color">{props.headinglarge}</h5>
                  <h5 class="title2 heading-color">{props.heading}</h5>
                  <h6 className="subheadings mt-4 heading-color">
                    {props.subheading}
                  </h6>
                  <p>
                    <strong>{props.detail}</strong>
                    {props.detailpara}
                  </p>
                  <h3 className="abouthealershort">{props.para}</h3>
                  <hr>{props.borderline}</hr>
                  <h4>{props.italichead2}</h4>
                  <strong>{props.strongpara}</strong>
                  <p>{props.para2}</p>
                  <p>
                    {props.para3}
                    <strong>{props.strongpara2}</strong>,
                    <strong>{props.strongpara3}</strong>
                  </p>
                  <p>{props.para4}</p>
                  <p>{props.para5}</p>
                  <p>{props.para6}</p>
                  <p>{props.para7}</p>
                  <h6 className="subheadings">{props.parahead}</h6>
                  <h4>{props.sign}</h4>
                  <div className="buttonparent">
                    {props.buttonname && (
                      <Buttonredirct
                        buttonname={props.button}
                        buttonname2={props.button2}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className=" innerpadding-all">
            <b className="Bolditalic">{props.para8}</b>
            <p>{props.para9}</p>
            <p>{props.para10}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutsection;
